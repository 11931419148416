/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em",
    h2: "h2",
    a: "a",
    div: "div",
    hr: "hr",
    ol: "ol",
    li: "li",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {PermaLink, Notes} = _components;
  if (!Notes) _missingMdxReference("Notes", true);
  if (!PermaLink) _missingMdxReference("PermaLink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.em, null, "Variational circuits are also known as \"parametrized quantum circuits\".")), "\n", React.createElement(_components.h2, {
    id: "adaptable-quantum-circuits-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adaptable-quantum-circuits-",
    "aria-label": "adaptable quantum circuits  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adaptable quantum circuits ", React.createElement(PermaLink, {
    title: "Adaptable quantum circuits"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Variational or parametrized quantum circuits are quantum algorithms that depend on free parameters. Like standard\nquantum circuits, they consist of three ingredients:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Preparation of a fixed ", React.createElement(_components.strong, null, "initial state"), " (e.g., the vacuum state or the zero state)."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "A ", React.createElement(_components.strong, null, "quantum circuit"), " $U(\\mathbf{\\theta})$, parameterized by\na set of free parameters $\\mathbf{\\theta}$."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Measurement"), " of an observable $\\hat{B}$ at the output. This observable may be made\nup from local observables for each wire in the circuit, or just a subset of wires."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Typically, the expectation values\n$f(\\theta)=\\langle 0 | U^\\dagger(\\theta) \\hat{B} U(\\theta) | 0 \\rangle$\nof one or more such circuits — possibly with some classical post-processing — define a scalar cost for a\ngiven task. The free parameters $\\theta = (\\theta_1, \\theta_2,...)$ of the circuit(s)\nare tuned to optimize this cost function."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 230px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 71.51898734177216%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAACRklEQVQ4y42S/UtTURjH97/2UwRRIJURTMVJCEEa1DQ1TVsxt+XLVjTd7qoNB7s33cJddd6RexFR2HQkbPftE/dud/MV+sLhnMPzfL/neZ7vcQE0m03SW1skxAxCOs33dBohlSKWSrEty2CamM6ih2tn08Rl7fuKwuBHHyMry7yMrTMmxBlPCExJIp5FPwcHB3Ty4TyDkp1jd3MWTr9Q2VlAa2yC2bJFbcG8ss/TdzPcHfHgnn/PZCrJcDDAUHCR0ZUV8nu7tmDrbxH9eI74cj+R+YfsTPYRnXrAifIKtD89wfVk0q7I+/MHc5LE7PZv3O5hBvvdPJ6ZIvNLIhRa5VD5Cg0fC5P38b25R2H6CcLCI44L46BXeoLhhMDz5SXuDAzg8fsZ+hzE4/Yw3PeMvum37BUV1JaK2qzD2RKl3DjlbS+cL1ErfaJ1EgG91hNMSyKjoSBj0W9MpJK8TiQY8n3gRXgVj8/HTqHQnaGpn4F2jKE2MPUmoF83xTQMisUi2VyOjCiylc0Si8eRJInTeh3DMDouGza5Vm8gy+25GkbbfTo/wGVfbkA0GiUWi3VIRvfbWDg8rLKxsdGp+nKsXaFp2iTdMND0dgvlcplcLmefNU3r5lgQRRGv13vjY66LVV0MVKtVu+WrcEQtVCqVLs+B6yrBCR4dHdltl0olZFm2H9B1nbW1NUKhEOFwGL/ff62QWwVVVbUrzOfztmHWCCxBQRAIBAK2YCQSoVarXeK5bjLkNqNuw3+17JjgLKetq/eL+ZbgP9kCABrOEvNwAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"variational circuit\"\n        title=\"variational circuit\"\n        src=\"/static/4187d71c48f845de3403bc7b19975e47/81c8e/variational_circuit.png\"\n        srcset=\"/static/4187d71c48f845de3403bc7b19975e47/c26ae/variational_circuit.png 158w,\n/static/4187d71c48f845de3403bc7b19975e47/81c8e/variational_circuit.png 230w\"\n        sizes=\"(max-width: 230px) 100vw, 230px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Variational circuits are trained by a classical optimization algorithm that makes queries to\nthe quantum device. The optimization is usually an iterative scheme that searches out better candidates for\nthe parameters $\\theta$ with every step."), "\n", React.createElement(_components.p, null, "Variational circuits have become popular as a way to think about quantum algorithms for near-term quantum devices.\nSuch devices can only run short gate sequences, since without fault tolerance every gate increases\nthe error in the output.\nUsually, a quantum algorithm is decomposed into a set of standard elementary operations,\nwhich are in turn implemented by the quantum hardware."), "\n", React.createElement(_components.p, null, "The intriguing idea of variational circuit for near-term devices is to merge\nthis two-step procedure into a single step by \"learning\" the circuit on the noisy device for a given task.\nThis way, the \"natural\" tunable gates of a device can be used to formulate the algorithm, without the\ndetour via a fixed elementary gate set. Furthermore, systematic errors can automatically be corrected\nduring optimization."), "\n", React.createElement(_components.h2, {
    id: "building-the-circuit-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#building-the-circuit-",
    "aria-label": "building the circuit  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Building the circuit ", React.createElement(PermaLink, {
    title: "Building the circuit"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 27.848101265822784%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABXElEQVQY022R20sCcRCF91/1teeeg5KgKDJdray0HkosCDIzu6xiN03LvGJJZiCIRqZpRq66vy+2LAo6cGYYGIbDfFI0lyOcTomT+BWhy0tC8Ti3xSJC06jX63S7XYQQX+ZLYuD/JA2ZzUx5d4Rx2saM04npYJ/JDTerDgcWWaZcLg+u9EEtojaT8J6CzjVqI8JLJYTWitCqBhH9VyRzQMGdzYhlJYBreYU1JcCcouD1eLiIRqnVahQKBZrNFzpPu7jsBlLHYzzfyRz7hglvGSltT3AkG2i3K0gj6+ssBBWx6Nhk3rmCzWRm1DiOzb6AVZbJ5/OonQ7dXo92xUfkcIz7pB31ycNjcYu36hGtkp/nBy9ar4FkOTtlKRYVsz4/psMDrKcnWP17BBWFWCz2mfBHWhNoA319+P+HZ8kk5+m0CGczeieUSJC4vfmzpAPRMehV00ATAzDfsH75A17upSmgx4EWAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"variational circuit\"\n        title=\"variational circuit\"\n        src=\"/static/782403f0742a23e4aec277e0499cedb2/f058b/variational_circuit2.png\"\n        srcset=\"/static/782403f0742a23e4aec277e0499cedb2/c26ae/variational_circuit2.png 158w,\n/static/782403f0742a23e4aec277e0499cedb2/6bdcf/variational_circuit2.png 315w,\n/static/782403f0742a23e4aec277e0499cedb2/f058b/variational_circuit2.png 630w,\n/static/782403f0742a23e4aec277e0499cedb2/40601/variational_circuit2.png 945w,\n/static/782403f0742a23e4aec277e0499cedb2/78612/variational_circuit2.png 1260w,\n/static/782403f0742a23e4aec277e0499cedb2/b60b8/variational_circuit2.png 3073w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The variational parameters $\\theta$, possibly together with an additional set of non-adaptable\nparameters $x = (x_1, x_2, ...)$, enter the quantum circuit as arguments for the circuit's gates.\nThis allows us to convert ", React.createElement(_components.em, null, "classical information"), " (the values $\\theta$ and $x$)\ninto ", React.createElement(_components.em, null, "quantum information"), " (the quantum state $U(x; \\theta)|0\\rangle$). As we will see in the\nexample below, the non-adaptable gate parameters usually play the role of ", React.createElement(_components.em, null, "data inputs"), " in quantum machine learning."), "\n", React.createElement(_components.p, null, "Quantum information is turned ", React.createElement(_components.em, null, "back into classical information"), " by evaluating the\nexpectation value of the observable $\\hat{B}$,"), "\n", React.createElement(_components.p, null, "$$\nf(x; \\mathbf{\\theta}) = \\langle \\hat{B} \\rangle = \\langle 0 | U^\\dagger(x;\\mathbf{\\theta})\\hat{B}U(x;\\mathbf{\\theta}) | 0 \\rangle.\n$$"), "\n", React.createElement(_components.p, null, "Beyond the basic rule that the parameters $\\mathbf{\\theta}$ are used as the arguments of gates,\nexactly how the gates are arranged, the ", React.createElement(_components.em, null, "circuit architecture"), ", is essentially arbitrary."), "\n", React.createElement(Notes, {
    title: "Note",
    type: "success",
    text: "As shown in the figure above, the circuit can also include additional gates $U$ which have no free parameters associated with them."
  }), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, {
    id: "example-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#example-",
    "aria-label": "example  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Example ", React.createElement(PermaLink, {
    title: "Example"
  })), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "As an example, consider a ", React.createElement(_components.em, null, "variational quantum classifier"), " which uses two variational circuits: The first circuit\nassociates the gate parameters with fixed data inputs, while the second circuit depends on free, trainable\nparameters. Together with a final measurement, this setup can be interpreted as a machine learning model."), "\n", React.createElement(_components.h3, {
    id: "data-embedding-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#data-embedding-",
    "aria-label": "data embedding  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Data embedding ", React.createElement(PermaLink, {
    title: "Data embedding"
  })), "\n", React.createElement(_components.p, null, "As explained in the section on ", React.createElement(_components.a, {
    href: "/qml/glossary/quantum_embedding"
  }, "quantum embeddings"), ",\nthe first few gates in the circuit can be used to embed\nthe input $x$ into a quantum state (which functions as a feature map, see\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1803.07128"
  }, "Schuld & Killoran et al. (2018)"), " and\n", React.createElement(_components.a, {
    href: "https://arxiv.org/abs/1804.11326"
  }, "Havlicek et al. (2018)"), "),\nwhile the subsequent gates have parameters $\\theta$ as arguments."), "\n", React.createElement(_components.p, null, "As an example, consider a photonic quantum computer (similar examples can be constructed for qubits). For\nsimplicity, we temporarily omit the parameters $\\theta$. We take the initial state to be the\n", React.createElement(_components.em, null, "vacuum"), " state and the measured observable $\\hat{B}$ to be the position operator $x$. The vacuum\nstate has expectation value $\\langle\\hat{x}\\rangle = \\langle 0 | \\hat{x} | 0 \\rangle = 0$."), "\n", React.createElement(_components.p, null, "Suppose we have an input $x$, which has $N$ dimensions. We can embed this into a quantum circuit\nwith $N$ wires using the ", React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Displacement_operator"
  }, "displacement operator"), ".\nFor every component $x_i$ of $x$, we apply $D(x_i)$ to wire $i$.\nThis is called displacement embedding."), "\n", React.createElement(_components.p, null, "Measurement of the expectation value of the $\\hat{x}$ operator on each wire will then give the result"), "\n", React.createElement(_components.p, null, "$$\n(\\langle \\hat{x}_1 \\rangle, \\cdots, \\langle \\hat{x}_N \\rangle ) = (x_1, \\dots, x_N).\n$$"), "\n", React.createElement(_components.p, null, "Thus, the displacement gate — combined with vacuum input and position measurements — can be used to\ndirectly encode data into a photonic quantum computer."), "\n", React.createElement(_components.h3, {
    id: "data-processing-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#data-processing-",
    "aria-label": "data processing  permalink",
    className: "hidden before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Data processing ", React.createElement(PermaLink, {
    title: "Data processing"
  })), "\n", React.createElement(_components.p, null, "Having embedded our data into a quantum state, we would now like to perform some processing. As it stands,\nour example circuit currently represents the ", React.createElement(_components.em, null, "identity"), " $f(x)=x$, which has no free parameters. By\nintroducing additional gates, with parameters $\\theta$, we can start building up more complex functions."), "\n", React.createElement(_components.p, null, "For clarity, we restrict to a one-dimensional input $x_1$ and add in a single rotation operator, with\nfree parameter $\\theta_1$. After applying this gate, the quantum node evaluated by our circuit becomes"), "\n", React.createElement(_components.p, null, "$$\nf(x_1;\\theta_1) = x_1 \\cos(\\theta_1).\n$$"), "\n", React.createElement(_components.p, null, "In summary, with only two quantum gates (displacement and rotation), we can evaluate functions of the above\nform using quantum circuits."), "\n", React.createElement(_components.p, null, "The above examples were kept very simple to illustrate the principles behind embedding data and parameters\ninto quantum circuits. Indeed, the function evaluated in the example is tractable classically. However, by\nincreasing the number of subsystems and the circuit depth, the corresponding functions can become progressively\nharder to evaluate classically, and a quantum device must be used."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
